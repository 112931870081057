<template>
    <v-dialog :value="value"
              @input="$emit('input', $event.target.value)"
              @click:outside="$emit('input', false)"

              :max-width="width">
        <v-card class="pa-4">
            <v-card-title class="mb-4 pa-0">
                <h3>{{ title }}</h3>
            </v-card-title>
            <v-card-actions>
                <v-spacer />
                <div>
                    <v-btn color="error"
                           class="mr-3"
                           @click="onDelete">
                        Да
                    </v-btn>
                    <v-btn color="secondary"
                           text
                           @click="$emit('input', false)">
                        Нет
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "Modal",
    props: {
        value: Boolean,
        width: {
            type: String,
            default: '600px'
        },
        deleteApi: String,
        title: {
            type: String,
            default: "Are you sure to delete?",
        },
    },
    methods: {
        async onDelete() {
            try {
                const res = await this.$api.delete(this.deleteApi);
                this.$emit('input', false);
                this.$emit('deleted');
            } catch (err) {
                this.showErr = true;
                this.err = err.response.data.error;
                console.log(err)
            }
        },
    },
}
</script>

<style scoped>

</style>