import Modal from "@/components/Modal";

export default {
  data: () => ({
    modal: false,
    dataObject: null
  }),
  components: { Modal },
  methods: {
    openForm (item = null, createDataObject) {
      this.dataObject = createDataObject(item);
      this.modal = true;
    },
    // saved (item) {
    //   const itemIndex = this.items.findIndex(it => it.id === item.id);
    //   itemIndex === -1 ? this.items.push(item) : this.items.splice(itemIndex, 1, item);
    // }
  }
}