import DeleteModal from "@/components/DeleteModal";

export default {
  components: {
    DeleteModal
  },
  data: () => ({
    deleteModal: false,
    deleteApi: '',
  }),
  methods: {
    deleteItem (deletingUrl) {
      this.deleteApi = deletingUrl;
      this.deleteModal = true;
    }
  }
}